var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-card",
        {
          staticClass: "cardClassDetailForm",
          attrs: { title: "법규별 규제사항" },
        },
        [
          _c(
            "template",
            { slot: "card-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _c("c-btn", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.editable,
                        expression: "editable",
                      },
                    ],
                    attrs: { label: "검증", icon: "check" },
                    on: { btnClicked: _vm.verification },
                  }),
                  _c("c-btn", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.editable,
                        expression: "editable",
                      },
                    ],
                    attrs: {
                      url: _vm.saveUrl,
                      isSubmit: _vm.isSave,
                      param: _vm.saveData,
                      mappingType: "PUT",
                      label: "LBLSAVE",
                      icon: "save",
                    },
                    on: {
                      beforeAction: _vm.saveInfo,
                      btnCallback: _vm.saveCallback,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "template",
            { slot: "card-detail" },
            _vm._l(_vm.bills, function (item, idx) {
              return _c(
                "div",
                { key: idx, staticClass: "col-12 divchklaw" },
                [
                  _c("div", { staticClass: "lawspan" }, [
                    _vm._v(_vm._s(item.regulationBillName)),
                  ]),
                  _vm._l(item.reguls, function (regul, _idx) {
                    return [
                      regul.regulatedItemCd !== "D10010007" &&
                      regul.regulatedItemCd !== "D10010008"
                        ? _c("q-checkbox", {
                            key: _idx,
                            staticClass: "customqcbox",
                            attrs: {
                              dense: "",
                              color: "orange-custom",
                              "true-value": "Y",
                              "false-value": "N",
                              disable: !_vm.editable,
                              label: regul.regulatedItemName,
                            },
                            model: {
                              value: regul.val,
                              callback: function ($$v) {
                                _vm.$set(regul, "val", $$v)
                              },
                              expression: "regul.val",
                            },
                          })
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              )
            }),
            0
          ),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }